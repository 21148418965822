import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './DownloadCarouselItem.scss';

import GatsbyImage from 'gatsby-image';
import { customPropTypes } from '../../../util';

function DownloadCarouselItem({ data: { icon, title, cta } }) {
  return (
    <a download href={cta?.file?.localFile?.publicURL} className="DownloadCarouselItem">
      <GatsbyImage className="icon" fluid={icon?.localFile?.childImageSharp.fluid} alt={icon?.altText} />
      <h4 className="title">{title}</h4>
      <p className="download-text">{cta.text}</p>
    </a>
  );
}

DownloadCarouselItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    icon: customPropTypes.image,
    title: PropTypes.string,
    cta: PropTypes.shape({
      file: PropTypes.object,
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
});

DownloadCarouselItem.defaultProps = {};

export default memo(DownloadCarouselItem);
